<!-- eslint-disable vue/no-useless-template-attributes -->
<template>
  <div class="orders-table">
    <b-pagination
      v-model="currentPage"
      :total-rows="1000000"
      :per-page="perPage"
      aria-controls="my-table"
      :hide-goto-end-buttons="true"
      :limit="1"
      class="pagination"
      @input="changePage()"
    >
      <template #prev-text>
        <img
          style="transform: rotate(180deg)"
          class="pagination__btn-icon"
          src="@/assets/png/database-icon-active.png"
          alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>
    <p v-if="orderPoints" class="pagination__text">
      {{ currentPage }}-{{ orderPoints.meta.last_page }} из
      {{ orderPoints.meta.total }}
    </p>
    <div class="table">
      <b-table
        v-if="orderPoints"
        id="my-table"
        class="table__box"
        :borderless="true"
        :items="orderPoints.data"
        :fields="fields"
        small
      >
        <template #cell(index)="data">
          {{ data.index + 1 + (currentPage - 1) * perPage }}
        </template>
        <template #cell(name)="data">
          <div class="table-name">
            <button class="table-name-btn">
              <div
                :class="{
                  '--yellow': data.item.region_id === null,
                }"
                @click="activeOrder(data.item.id)"
              >
                {{ data.item.name }}
              </div>
              <div class="table-box flex">
                <img
                  v-if="data.item.fav"
                  src="@/assets/png/analytics-icon.png"
                  alt=""
                  @click="addToFav(data.item)"
                />
                <img
                  v-if="!data.item.fav"
                  src="@/assets/png/fav.png"
                  alt=""
                  @click="addToFav(data.item)"
                />
                <img
                  v-if="data.item.priority === 'default'"
                  src="@/assets/png/priority-empty.png"
                  alt=""
                  @click="addToPriority(data.item)"
                />
                <img
                  v-else
                  src="@/assets/png/priority.png"
                  alt=""
                  @click="addToPriority(data.item)"
                />
              </div>
            </button>
          </div>
        </template>
        <template #cell(status)="data">
          <div style="display: flex"
          >
            <router-link
              v-if="data.item.region_id !== null"
              :to="routerOrder(data.item)"
              class="table-btn__status"
              :style="{
                backgroundColor: statusColor(data.item.status),
                color: statusColorText(data.item.status),
              }"
            >
              {{ statusChange(data.item.status) }}
            </router-link>
            <router-link
              v-else
              :to="routerOrder()"
              class="table-btn__status table-btn__status--null-region"
              :style="`background: #ed8230; color: #FFFFFF`"
            >
              Регион не найден
            </router-link>
          </div>
        </template>
        <template #cell(transport)="data">
          <p>{{ data.item.transport }}</p>
        </template>
        <template #cell(loading)="data" colspan="3">
          <div class="working__road flex">
            <div class="working__road-block table__road-block">
              <div class="working__road-box" :style="`width: ${data.item.loading}%;`" />

              <div class="working__road-box-after" />
            </div>
          </div>
        </template>
        <template #cell(counteragent)="data">
          <p>{{ data.item.filial }}</p>
        </template>
        <template #cell(start_date)="data">
          <p>
            {{ dateProduct(data.item.date) }} в
            {{ timeProduct(data.item.date) }}
          </p>
        </template>
        <template #cell(end_date)="data">
          <p>
            {{ dateProduct(data.item.date) }} в
            {{ timeProduct(data.item.date) }}
          </p>
        </template>
        <template #cell(order_id)="data">
          <p v-if="data.item.order_id">000000{{ data.item.order_id }}</p>
          <p v-else>-</p>
        </template>
        <template #cell(final_name)="data">
          <p v-if="data.item.final_name">
            {{ data.item.final_name }}
          </p>
          <p v-else>-</p>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import statusMixin from "@/mixins/status";

export default {
  mixins: [statusMixin],
  data() {
    return {
      textSearchTimer: null,
      swowItem: null,
      perPage: 15,
      currentPage: 1,
      filterProduct: [],
      options: [
        { value: null, text: "10" },
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5", disabled: true },
      ],
      fields: [
        {
          key: "index",
          label: "Номер",
          sortable: false,
          class: "tb-all tb-sm",
        },
        {
          key: "name",
          label: "Заказы",
          sortable: true,
          class: "tb-all",
          isRowHeader: true,
        },
        {
          key: "final_name",
          label: "Номер реализации",
          sortable: true,
          class: "tb-all",
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "counteragent",
          label: "Контрагент",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "order_id",
          label: "Номер маршрута",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "from",
          label: "Откуда",
          isRowHeader: true,
          sortable: true,
          class: "tb-all",
        },
        {
          key: "to",
          label: "Куда",
          isRowHeader: true,
          sortable: true,
          class: "tb-all",
        },
        {
          key: "start_date",
          label: "Дата создания",
          sortable: true,
          class: "tb-all",
        },
      ],
    };
  },
  computed: {
    rows() {
      if (this.orderPoints.data) {
        return this.filter.length;
      }
      return 1;
    },
    ...mapState([
      "orderPoints",
      "textFilter",
      "statusFilter",
      "addressFilter",
      "dateFilter",
    ]),
  },
  watch: {
    async currentPage() {
      await this.search();
    },
    textFilter() {
      if (this.textSearchTimer !== null) {
        clearTimeout(this.textSearchTimer);
      }
      this.textSearchTimer = setTimeout(async () => {
        await this.search();
        this.currentPage = 1;
      }, 1500);
    },
    async statusFilter() {
      this.currentPage = 1;
      await this.search();
    },
    async addressFilter() {
      this.currentPage = 1;
      await this.search();
    },
    async dateFilter() {
      this.currentPage = 1;
      await this.search();
    },
  },
  created() {
    if (this.$route.query.page != undefined) {
      this.currentPage = this.$route.query.page
    }
    
    this.$store.commit("statusFilter", null);
    if (!this.orderPoints)
      this.search();  

    this.$store.commit("dateFilter", null);
  },
  methods: {
    routerOrder(item = null) {
      try {
        if (item) {
          return `/route/${item.order_id}`;
        } else {
          return "#";
        }
      } catch (error) {
        return "#";
      }
    },
    paginationShow() {
      this.perPage = this.swowItem;
    },
    dateProduct(value) {
      const date = value.split(" ")[0].split("-");
      return date[2] + "." + date[1] + "." + date[0];
    },
    timeProduct(value) {
      const time = value.split(" ")[1].split(":");
      return time[0] + ":" + time[1];
    },
    activeOrder(value) {
      this.$router.push(`/order/order-points?id=${value}`);
    },
    addToFav(item) {
      item.fav = !item.fav;
      this.$api
        .post("/web/point-add-fav", {
          point_id: item.id,
          fav: item.fav ? "true" : "false",
        })
        .then(() => {
          this.$toast.success("Успешно");
        })
        .catch((e) => {
          if (e.response) {
            item.fav = !item.fav;
            this.$toast.error(e.response.data.message);
          }
        });
    },
    addToPriority(item) {
      item.priority = item.priority === "default" ? "flash" : "default";
      this.$api
        .post("/web/point-add-priority", {
          point_id: item.id,
          priority: item.priority,
        })
        .then(() => {
          this.$toast.success(
            item.priority === "default"
              ? "Успешно убрана приоритетность"
              : "Успешно присвоена приоритетность"
          );
        })
        .catch((e) => {
          if (e.response) {
            this.$toast.error(e.response.data.message);
            item.priority = item.priority === "default" ? "flash" : "default";
          }
        });
    },
    async search() {
      await this.$store.dispatch("getOrderPoints", {
        title: this.textFilter,
        status: this.statusFilter,
        page: this.currentPage,
        paginate_page: this.perPage,
        from_date: this.dateFilter ? this.dateFilter?.start + " 00:00:00" : "",
        to_date: this.dateFilter ? this.dateFilter.end + " 00:00:00" : "",
        city_id: this.addressFilter !== "Местоположение" ? this.addressFilter : "",
      });
    },
    changePage() {
      const searchURL = new URL(window.location);
      searchURL.searchParams.set('page', this.currentPage);
      window.history.pushState({}, '', searchURL)
    },
  },
};
</script>

<style scoped>
.--yellow {
  color: #ed8230;
}
.table {
  width: 100%;
}
.table-name {
  padding-left: 5px;
}
.table-name-btn {
  width: 100%;
  position: relative;
  padding-right: 35px;
  box-sizing: border-box;
  text-align: start;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--secondary-color);
  background: transparent;
}
.table-name-btn:focus {
  outline: none;
  box-shadow: none;
}
.table-name-btn p {
  font-size: 10px;
  line-height: 12px;
  color: #acacac;
}
.table-box {
  width: 25px;
  position: absolute;
  top: 0px;
  right: 5px;
}
.table-btn__status {
  text-align: center;
  color: #fff;
  margin: auto;
  width: 100%;
}
/* --- */
.table-pagination {
  margin: 15px 0;
  padding: 15px 5px 15px 15px;
  background: #e0e9fa;
  border-radius: 15px;
}
.pagination__text {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #306cdc;
}
.pagination__box {
  width: 300px;
  height: 30px;
}
.pagination__text-inner {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  color: #707070;
}
.pagination__select-block {
  position: relative;
  background: #ffffff;
  border-radius: 5px;
  color: #707070;
  width: 60px;
  margin: 0 25px 0 10px;
  color: #83b1f4;
}
.pagination,
.pagination__text {
  position: absolute;
  top: -151px;
  right: 10px;
}
.pagination__text {
  position: absolute;
  top: -139px;
  right: 130px;
}
.pagination__select {
  border: 1px solid #83b1f4;
  color: #83b1f4;
}
.main__select-icon {
  right: 8px;
}
.page-item {
  margin-right: 10px;
  border: 1px solid #83b1f4;
  border-radius: 5px;
}
.page-item:nth-child(2) {
  display: none;
}
.pagination__btn-icon-left {
  transform: rotate(180deg);
}
.working__road {
  margin: 0;
}
.table__road-block {
  width: 95%;
}
.table__road-block::before {
  right: 5px;
  top: -3px;
}
.table-box img {
  width: 12px;
}
</style>
